import { render, staticRenderFns } from "./HomeTestimonials.vue?vue&type=template&id=49fde6b7&scoped=true&"
import script from "./HomeTestimonials.vue?vue&type=script&lang=js&"
export * from "./HomeTestimonials.vue?vue&type=script&lang=js&"
import style0 from "./HomeTestimonials.vue?vue&type=style&index=0&id=49fde6b7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49fde6b7",
  null
  
)

/* custom blocks */
import block0 from "./HomeTestimonials.vue?vue&type=custom&index=0&blockType=syle&lang=scss"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/codebuild/output/src739226531/src/0707-vue/components/Img.vue').default})
