import { render, staticRenderFns } from "./HomeCaseStudies.vue?vue&type=template&id=049f3303&scoped=true&"
import script from "./HomeCaseStudies.vue?vue&type=script&lang=js&"
export * from "./HomeCaseStudies.vue?vue&type=script&lang=js&"
import style0 from "./HomeCaseStudies.vue?vue&type=style&index=0&id=049f3303&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049f3303",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeCaseStudyItem: require('/codebuild/output/src739226531/src/0707-vue/components/HomeCaseStudyItem.vue').default})
