import { render, staticRenderFns } from "./index.vue?vue&type=template&id=04835880&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHero: require('/codebuild/output/src739226531/src/0707-vue/components/HomeHero.vue').default,HomeLogos: require('/codebuild/output/src739226531/src/0707-vue/components/HomeLogos.vue').default,HomeTestimonials: require('/codebuild/output/src739226531/src/0707-vue/components/HomeTestimonials.vue').default,HomeCaseStudies: require('/codebuild/output/src739226531/src/0707-vue/components/HomeCaseStudies.vue').default,HomeServices: require('/codebuild/output/src739226531/src/0707-vue/components/HomeServices.vue').default,HomeFeatures: require('/codebuild/output/src739226531/src/0707-vue/components/HomeFeatures.vue').default,HomeBlog: require('/codebuild/output/src739226531/src/0707-vue/components/HomeBlog.vue').default,Faq: require('/codebuild/output/src739226531/src/0707-vue/components/Faq.vue').default})
